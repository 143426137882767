import * as Actions from './ActionTypes'

const initialState = {
    loading: true,
    supervisorData: [],
    error: null,
    totalPage: 0
}

const SupervisorReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.LOADING:
            return {
                ...state,
                loading: true
            }
        case Actions.GET_DATA:
            return {
                ...state,
                supervisorData: action.payload,
                totalPage: action.totalPage,
                loading: false
            }
        case Actions.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export default SupervisorReducer