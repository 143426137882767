import React, { useState, useEffect, useMemo } from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import './scss/style.scss';
import AuthContext,{OnlineStatusContext} from './Context';
import { Provider } from 'react-redux'
import store from './store';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


//Network Connection
// const PING_RESOURCE = "./ping.txt";
// const TIMEOUT_TIME_MS = 3000;
// const onlinePollingInterval = 10000;

// const timeout = (time ,promise) => {
//   return new Promise(function(resolve, reject) {
//     setTimeout(() => {
//       reject(new Error("Request timed out."));
//     }, time);
//     promise.then(resolve, reject);
//   });
// };

// const checkOnlineStatus = async () => {
//   const controller = new AbortController();
//   const { signal } = controller;

//   // If the browser has no network connection return offline
//   if (!navigator.onLine) return navigator.onLine;

//   //
//   try {
//     await timeout(
//       TIMEOUT_TIME_MS,
//       fetch(PING_RESOURCE, {
//         method: "GET",
//         signal
//       })
//     );
//     return true;
//   } catch (error) {
//     // Error Log
//     console.error(error);

//     // This can be because of request timed out
//     // so we abort the request for any case
//     controller.abort();
//   }
//   return false;
// };


//APP.JS
const loading = (
  <div className="pt-3 text-center">
  <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
  )

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = (props) => {

  const dispatch = useDispatch()
  const [loginCredentials, setloginCredentials] = useState(false)

  const {permission} = useSelector(state => state.userdata)


  //Network Connection
  // const [onlineStatus, setOnlineStatus] = useState(true)
  // useEffect(() => {
  //   window.addEventListener("offline", () => {
  //     setOnlineStatus(false);
  //   });

  //   // Add polling incase of slow connection
  //   const id = setInterval(() => {
  //     checkStatus();
  //   }, onlinePollingInterval);

  //   return () => {
  //     window.removeEventListener("offline", () => {
  //       setOnlineStatus(false);
  //     });

  //     clearInterval(id);
  //   };
  // }, []);

  // const checkStatus = async () => {
  //   const online = await checkOnlineStatus();
  //   setOnlineStatus(online);
  // };
  //End of Network
  // useEffect(() => {
  //   var tempUserData = localStorage.getItem('userData')
  //   var userData = JSON.parse(tempUserData)
  //   if (userData != null) {
  //     setloginCredentials(true)
  //   } else {
  //     setloginCredentials(false)
  //   }
  // }, [])


  const contextConst = useMemo(() => ({
    signIn: (data) => {
    
      localStorage.setItem("userData", JSON.stringify(data))
      dispatch({ type: "userdata", payload: data})
      setloginCredentials(true)
    
    },
    signOut: () => {
      
      dispatch({ type: 'logout' })
      localStorage.removeItem("userData")
      setloginCredentials(false)

    },

  }))


  const isAuthenticated = () => {
    //write your condition here
    var tempUserData = localStorage.getItem('userData')
    var userData=JSON.parse(tempUserData)
    console.log("UserData",userData)

    if(Object.keys(permission).length === 0 && userData != null ) {
      dispatch({ type: "userdata", payload: userData})
    }

    if(userData!=null)
    {
      global.profileInfo=userData
      return true;
    }
    return false;
  
  }


  const AuthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      isAuthenticated()
      ? <Component {...props} />
      : <Redirect to='/login' />
      )} />
    )


  const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      !isAuthenticated()
      ? <Component {...props} />
      : <Redirect to='/' />
      )} />
    );


  return (
    <Provider store={store}>
    <AuthContext.Provider value={contextConst}>

    <BrowserRouter basename='/'>
    <React.Suspense fallback={loading}>
    <Switch>
    <UnauthenticatedRoute exact path="/login" name="Login Page" component={Login} />
{/* <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} /> */}
{/* <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} /> */}
    <Route exact path="/404" name="Page 404" component={Page404} />
    <Route exact path="/500" name="Page 500" component={Page500} />
    <AuthenticatedRoute path="/" name="Home" component={TheLayout} />

{/* <Route path="/" name="Home" render={props => <TheLayout {...props}/>} /> */}
    </Switch>
    </React.Suspense>
    </BrowserRouter>
    <ToastContainer position='top-right' autoClose={3000} closeButton={false} hideProgressBar={true} />

    </AuthContext.Provider>
    </Provider>
    );
}

export default App;
