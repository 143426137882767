import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
// import DashboardReducer from './views/sidenavbar/dashboard/Reducer'
import SupervisorReducer from './views/sidenavbar/Supervisior/Reducer'
import DirectoryReducer from './views/sidenavbar/Phone_Directory/Reducer'
import ManageTicketsReducer from './views/sidenavbar/Tickets/Reducer'
import ManageSettingsReducer from './views/sidenavbar/SettingsPage/Reducer'
import CalloutReducer from './views/sidenavbar/Call_Out/Reducer'
import WheatherReducer from './views/sidenavbar/dashboard/Reducer'
import LoginReducer from './views/pages/login/Reducer'

const appReducer = combineReducers({
  //dashboard: DashboardReducer,

  supervisor: SupervisorReducer,
  currentState: DirectoryReducer,
  ManageTickets:ManageTicketsReducer,
  ManageSettings:ManageSettingsReducer,
  callout:CalloutReducer,
  userdata: LoginReducer,
  Wheather:WheatherReducer,
})

const rootReducer = (state, action) => {

  if (action.type === 'logout') {
    state = undefined;
  }

  console.log("store11",state);
  console.log("action11",action);

  return appReducer(state, action)

}

const store = createStore(rootReducer, applyMiddleware(thunk))
export default store