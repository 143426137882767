import * as Actions from './ActionTypes'

const initialState = {
    loading: true,
    directoryData: [],
    error: null,
    totalPage: 0,
    selectedItems: []
}

const DirectoryReducer = (state = initialState, action) => {
    console.log("DirectoryReducer",action.type);
    switch (action.type) {
    case Actions.LOADING:
        return {
            ...state,
            loading: true
        }
    case Actions.GET_DATA:
        return {
            ...state,
            directoryData: action.payload,
            totalPage: action.totalPage,
            loading: false
        }
    case Actions.ERROR:
        return {
            ...state,
            loading: false,
            error: action.error
        }
    default: return state
    }
}

export default DirectoryReducer