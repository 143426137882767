

const initialState = {
    defaultlocationDetails: {
        "location_mongoDB": {
          "type": "Point",
          "coordinates": [
          -77.61307994723929,
          43.16058005353463
          ]
      },
      "location": {
          spatialReference: {wkid: 4326, latestWkid: 4326},
          x: -77.61307994723929,
          y: 43.16058005353463
      },
      road_details_index:null,
      road_details_Arr:[],
      From:"",
      To:"",
      address:{
          AddNum: "",
          Addr_type: "StreetName",
          Address: "I-490 E",
          Block: "",
          City: "Rochester",
          CntryName: "United States",
          CountryCode: "USA",
          District: "",
          LongLabel: "I-490 E, Rochester, NY, 14608, USA",
          Match_addr: "I-490 E, Rochester, New York, 14608",
          MetroArea: "",
          Neighborhood: "Corn Hill",
          PlaceName: "",
          Postal: "14608",
          PostalExt: "",
          Region: "New York",
          RegionAbbr: "NY",
          Sector: "",
          ShortLabel: "I-490 E",
          Subregion: "Monroe County",
          Territory: "",
          Type: ""
      }
  },
  ticketTableData:{},
  tabLocationData:{},
  ticketManagementList:[],
  ActiveticketTableData:[],
  roadway_info:{},
  dropdown_data:{},
}

const ManageTicketsReducer = (state = initialState, action) => {
    switch (action.type) {

        case "mapLocation":
        return {
            ...state,
            defaultlocationDetails: action.payload,
            
        }
        case "ticketTableData":
        return {
            ...state,
            ticketTableData:{...action.payload}
        }
        case "tabLocation":
        return{
            ...state,
            tabLocationData:{...action.payload}
        }
        case "ActiveticketTableData":
        return{
            ...state,
            ActiveticketTableData:action.payload
        }  
        case "ticketmanagementList":
        return{
            ...state,
            ticketManagementList:action.payload
        }    
        case "roadway_info":
        return{
            ...state,
            roadway_info:{...action.payload}
        }
        
        case "reset_location":
        return initialState
        default: return state


    }

}

export default ManageTicketsReducer