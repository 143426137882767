
const initialState = {
  location:{},
  current:{},
  forecast:{}
}

const WheatherReducer = (state = initialState, action) => {
    switch (action.type) {

        case "wheather":
        return {
            ...state,
            location:action.payload.location,
            current:action.payload.current,
            forecast:action.payload.forecast
        }
        case "ticket_progress":
        return {
            ...state,
            ticketProgress : action.payload,
        }
        case "dig_count":
        return {
            ...state,
            dig_count : action.payload
        }
        default: return state

    }
}

export default WheatherReducer