const initialState = {
    manageDropDown:{}
}
const ManageSettingsReducer = (state = initialState, action) => {
    switch (action.type) {

        case "manageDropDown":
            return {
                ...state,
                manageDropDown: action.payload,
                
                    }
   
        case "reset":
            return initialState
        default: return state


    }

}

export default ManageSettingsReducer