import * as Actions from './ActionTypes'

const initialState = {
    loading: true,
    driverData: [],
    error: null,
    totalPage: 0,
    selectedItems: []
}

const CalloutReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.LOADING:
            return {
                ...state,
                loading: true
            }
        case Actions.GET_DATA:
            return {
                ...state,
                driverData: action.payload,
                totalPage: action.totalPage,
                loading: false
            }
        case Actions.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case Actions.SELECTING:
            return {
                ...state,
                selectedItems: action.selectedItems
            }
        default: return state
    }
}

export default CalloutReducer